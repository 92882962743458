import { IBookable } from '@/interfaces/ibookable'

export class Workplace implements IBookable {
  id = -1
  code = ''
  type = 'workplace'
  description = ''
  equipments = []
  locationId = -1
}
