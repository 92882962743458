










import AppFooter from '@/components/AppFooter.vue'
import BottomNav from '@/components/BottomNav.vue'
import TopNav from '@/components/TopNav.vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  components: {
    TopNav,
    BottomNav,
    AppFooter
  },
  computed: {
    ...mapState(['loggedin'])
  }
})
export default class App extends Vue {
  loggedin!: boolean
}
