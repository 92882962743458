import { IBookable } from '@/interfaces/ibookable'
import { Tenant } from '@/interfaces/tenant'

export class Location {
  id = -1
  tenant?: Tenant
  address?: string
  imageUrl?: string
  mapUrl?: string
  bookables: IBookable[] = []
}
