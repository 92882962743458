







































import { Tenant } from '@/interfaces/tenant'
import { User } from '@/interfaces/user'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { SearchIcon, SettingsIcon } from 'vue-feather-icons'

@Component({
  components: {
    SearchIcon, SettingsIcon
  },
  computed: {
    ...mapState(['tenant', 'loggedin', 'loginUser'])
  }
})
export default class TopNav extends Vue {
  tenant!: Tenant
  loginUser!: User
  screen = ''

  @Prop({ type: Boolean, default: false }) isDesktop!: boolean
  @Prop({ type: Boolean, default: false }) isMobile!: boolean

  mounted () {
    this.screen = this.$route?.name ?? 'n/a'
  }
}
