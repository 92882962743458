import BookDateSelect from '@/views/BookDateSelect.vue'
import LocationSelect from '@/views/LocationSelect.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Settings from '@/views/Settings.vue'
import WorkplaceSelect from '@/views/WorkplaceSelect.vue'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/location',
    name: 'LocationSelect',
    component: LocationSelect
  },
  {
    path: '/select-book-date',
    name: 'BookDateSelect',
    component: BookDateSelect
  },
  {
    path: '/workplace',
    name: 'WorkplaceSelect',
    component: WorkplaceSelect
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
