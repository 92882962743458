














































































import BottomNav from '@/components/BottomNav.vue'
import TopNav from '@/components/TopNav.vue'
import { User } from '@/interfaces/user'
import { AppLanguage } from '@/interfaces/lang-choice'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Edit2Icon } from 'vue-feather-icons'

@Component({
  components: {
    TopNav,
    BottomNav,
    Edit2Icon
  },
  computed: {
    ...mapState(['loggedin', 'loginUser'])
  }
})
export default class Settings extends Vue {
  AppLanguage = AppLanguage

  loginUser!: User
  user: User = {} as any

  mounted () {
    this.user = Object.assign(new User(-1, ''), { ...this.loginUser })
  }

  save (event: Event) {
    event.preventDefault()
    this.$store.dispatch('SaveUser', { user: this.user })
  }
}
