import { IBookable } from '@/interfaces/ibookable'
import { Tenant } from '@/interfaces/tenant'
import { User } from '@/interfaces/user'
import { Location } from '@/interfaces/location'
import { Workplace } from '@/interfaces/workplace'
import { api } from '@/lib/api'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    requestsPending: 0,
    savePeding: 0,

    loggedin: false,
    loginUser: new User(-1, ''),
    tenant: new Tenant(),

    selectedLocation: new Location(),
    selectedDate: new Date(),
    selectedTimeFrom: '08:30',
    selectedTimeTo: '17:00',
    selectedWorkplace: new Workplace(),

    profileEditing: false,
    profileSaveError: '',

    workplaces: [] as IBookable[],
    locations: [] as Location[]
  },
  getters: {
    loading (state): boolean {
      return state.requestsPending > 0
    },
    saving (state): boolean {
      return state.savePeding > 0
    }
  },
  mutations: {
    initializeStore (state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')!))
        )
      }
    },
    loading (state) {
      state.requestsPending++
    },
    loadend (state) {
      if (state.requestsPending) state.requestsPending--
    },
    saving (state) {
      state.savePeding++
    },
    saveend (state) {
      state.savePeding--
    },

    profileEdit (state) {
      state.profileEditing = true
      state.profileSaveError = ''
    },
    profileEditEnd (state) {
      state.profileEditing = false
      state.profileSaveError = ''
    },
    profileSaveError (state, { error }: { error: Error | string }) {
      state.profileSaveError = typeof error === 'object' ? error.message : error
    },
    loadUser (state, { user }: { user: User }) {
      state.loginUser = user
    },

    login (state, { user }: { user: User }) {
      state.loginUser = user
      state.tenant = user.tenant!
      state.loggedin = true
    },
    logout (state) {
      state.loginUser = new User(-1, '')
      state.tenant = new Tenant()
      state.loggedin = false
    },

    loadLocations (state, { locations }: { locations: Location[] }) {
      state.locations = locations
    }
  },
  actions: {
    async login ({ commit }, { email, password }: { email: string; password: string }) {
      commit('loading')
      const user = await api.login(email, password)
      commit('login', { user })
      commit('loadend')
    },
    logout ({ commit }) {
      commit('logout')
    },

    async saveProfile ({ commit }, { user }: { user: User }) {
      commit('saving')
      let saved = false

      try {
        await api.saveUser(user)
        saved = true
      } catch (e) {
        commit('profileSaveError', { error: e })
      } finally {
        commit('saveend')
      }

      if (saved) {
        commit('loaduser', { user })
        commit('profileEditEnd')
      }
    },

    async loadLocations ({ commit }) {
      let locations: Location[] = []

      try {
        locations = await api.getLocations()
      } catch (e) {
        commit('resourceLoadError', { error: e })
      }

      commit('loadLocations', { locations })
    }
  },
  modules: {
  }
})

store.subscribe((mutation, state) => {
  localStorage.setItem('store', JSON.stringify(state))
})

export default store
