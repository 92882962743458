




import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['loggedin'])
  }
})
export default class Logout extends Vue {
  loggedin!: boolean

  @Watch('loggedin')
  onLoggedinChange (loggedin: boolean) {
    if (!loggedin) {
      this.$router.push({ name: 'Login' })
    }
  }

  mounted () {
    this.$store.dispatch('logout')
  }
}
