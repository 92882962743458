









import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['loggedin'])
  }
})
export default class AppFooter extends Vue {
  loggedin!: boolean
}
