
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['loggedin'])
  }
})
export default class BottomNav extends Vue {
  loggedin!: boolean
  @Prop({ default: false, type: Boolean }) hasNav!: boolean
}
