import { AppLanguage } from '@/interfaces/lang-choice'
import { Tenant } from '@/interfaces/tenant'
import { User } from '@/interfaces/user'
import { Location } from '@/interfaces/location'
import axios, { AxiosInstance } from 'axios'

export class Api {
  http: AxiosInstance

  constructor () {
    this.http = axios.create({
      baseURL: '/api'
    })
  }

  login (email: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      const user = new User(42, 'john.doe@fancymail.cool')
      user.language = AppLanguage.en
      user.about = 'Lorem ipsum'
      user.department = 'Development'
      user.lastName = 'Doe'
      user.firstName = 'John'
      user.function = 'Developer'
      user.avatarUrl = 'https://www.discordavatars.com/wp-content/uploads/2020/07/marvel-character-avatar-016.jpg'
      user.favoriteLocations = [1]

      const tenant = new Tenant()
      tenant.companyName = 'Banauten'
      tenant.logoUrl = '/img/tenant-logo-placeholder.svg'

      user.tenant = tenant

      setTimeout(() => resolve(user), 1000)
    })
  }

  getLocations (): Promise<Location[]> {
    return new Promise<Location[]>((resolve: (locations: Location[]) => void, reject) => {
      setTimeout(() => {
        resolve([
          {
            id: 1,
            address: 'Wilhelmstraße 11, 70180 Stuttgart',
            imageUrl: '/img/floor-1.png',
            mapUrl: '/img/Office-STR-v3.svg',
            bookables: [
              {
                code: 'seat-STR-001',
                id: 1,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-002',
                id: 2,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-003',
                id: 3,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-004',
                id: 4,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-005',
                id: 5,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-006',
                id: 6,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-007',
                id: 7,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-008',
                id: 8,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-009',
                id: 9,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              },
              {
                code: 'seat-STR-010',
                id: 10,
                type: 'workplace',
                equipments: [
                  { name: 'keyboard' },
                  { name: 'mouse' },
                  { name: 'monitor' },
                  { name: 'adjustable desk' },
                  { name: 'speakers' }
                ],
                description: 'Lorem Ipsum dolor sit amet, consecteteur adipiscim elit'
              }

            ]
          },
          {
            id: 2,
            address: 'Wilhelmstraße 11, 70180 Stuttgart',
            imageUrl: '/img/floor-2.png',
            mapUrl: '/img/Parking-STR-v4.svg',
            bookables: [
            ]
          }
        ])
      }, 1000)
    })
  }

  async saveUser (user: User): Promise<void> {
    return new Promise<void>(resolve => setTimeout(() => resolve, 1000))
  }
}

export const api = new Api()
