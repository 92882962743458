import { AppLanguage } from '@/interfaces/lang-choice'
import { Tenant } from '@/interfaces/tenant'

export class User {
  password?: string
  firstName?: string
  lastName?: string
  department?: string
  function?: string
  about?: string
  language: AppLanguage = AppLanguage.en
  avatarUrl?: string

  tenant?: Tenant
  favoriteLocations?: number[]

  constructor (public id: number, public email: string) {
  }

  get initials (): string {
    return [this.firstName, this.lastName].map(n => n && n[0]).join('')
  }

  get fullname (): string {
    return [this.firstName, this.lastName].join(' ')
  }
}
