





























import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserIcon, LockIcon } from 'vue-feather-icons'
import { mapState, mapGetters } from 'vuex'

@Component({
  components: {
    UserIcon, LockIcon
  },
  computed: {
    ...mapState(['loggedin']),
    ...mapGetters(['loading'])
  }
})
export default class Login extends Vue {
  email = ''
  password = ''

  loading!: boolean
  loggedin!: boolean

  mounted () {
    this.onLoggedinChange(this.loggedin)
  }

  @Watch('loggedin')
  onLoggedinChange (loggedin: boolean) {
    if (loggedin) {
      this.$router.push('/location')
    }
  }

  login (event: Event) {
    event.preventDefault()
    this.$store.dispatch('login', { email: this.email, password: this.password })
  }
}
